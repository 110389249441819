/// <reference path="o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts" />

import type { TruncateIndexDBObjectStoreMode } from "o365.pwa.types.ts";
import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

import type * as ApiPwaOfflineSyncOptionsModule from 'o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiRequestOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts')>("o365.pwa.modules.sw.apiRequestOptions.ApiRequestOptions.ts");

    function isGenerateOfflineDataOptions(obj: any): obj is ApiPwaOfflineSyncOptionsModule.IGenerateOfflineDataOptions {
        return obj.shouldGenerateOfflineData;
    }

    class ApiPwaOfflineSyncOptions implements ApiPwaOfflineSyncOptionsModule.ApiPwaOfflineSyncOptions {
        public readonly dataObjectId: string;
        public readonly personID?: string;
        public readonly rowCountTimeout?: number;
        public readonly requestGuid: string;
        public readonly appId: string;
        public readonly dataSourceId: string;
        public readonly shouldGenerateOfflineData: boolean;
        public readonly viewName: string;
        public readonly fields: any;
        public readonly distinctRows: boolean;
        public readonly filterString: string;
        public readonly whereClause: string;
        public readonly masterDetailString: string;
        public readonly originalViewName?: string;
        public readonly offlineDataType?: string;
        public readonly offlineDataProcName?: string;
        public readonly appIdOverride?: string;
        public readonly databaseIdOverride?: string;
        public readonly objectStoreIdOverride?: string;
        public readonly truncateMode: TruncateIndexDBObjectStoreMode;
        public readonly deviceRef?: string;
        public readonly fileStore?: boolean;
        public readonly expandView?: boolean;
        public readonly definitionProc?: string;
        public readonly definitionProcParameters?: any;
        public readonly failOnNoRecords?: boolean;
        public readonly skipStatusUpdate?: boolean;

        constructor(options: ApiPwaOfflineSyncOptionsModule.IApiPwaOfflineSyncBaseOptions)
        constructor(options: ApiPwaOfflineSyncOptionsModule.IGenerateOfflineDataOptions)
        constructor(options: ApiPwaOfflineSyncOptionsModule.IApiPwaOfflineSyncBaseOptions | ApiPwaOfflineSyncOptionsModule.IGenerateOfflineDataOptions) {
            this.dataObjectId = options.dataObjectId;
            this.personID = options.personID;
            this.rowCountTimeout = options.rowCountTimeout;
            this.requestGuid = options.requestGuid;
            this.appId = options.appId;
            this.dataSourceId = options.dataSourceId;
            this.viewName = options.viewName;
            this.fields = options.fields;
            this.distinctRows = options.distinctRows;
            this.filterString = options.filterString;
            this.whereClause = options.whereClause;
            this.masterDetailString = options.masterDetailString;
            this.shouldGenerateOfflineData = options.shouldGenerateOfflineData;
            this.objectStoreIdOverride = options.objectStoreIdOverride;
            this.truncateMode = options.truncateMode;
            this.fileStore = options.fileStore;
            this.expandView = options.expandView;
            this.definitionProc = options.definitionProc;
            this.definitionProcParameters = options.definitionProcParameters;
            this.skipStatusUpdate = options.skipStatusUpdate;

            if (isGenerateOfflineDataOptions(options)) {
                this.originalViewName = options.originalViewName;
                this.offlineDataType = options.offlineDataType;
                this.offlineDataProcName = options.offlineDataProcName;
                this.deviceRef = options.deviceRef;
                this.failOnNoRecords = options.failOnNoRecords;
            }
        }

        static async fromRequest(request: Request): Promise<InstanceType<typeof ApiRequestOptions<ApiPwaOfflineSyncOptions>>> {
            const clonedRequest = request.clone();
            const body = await clonedRequest.json();

            return new ApiRequestOptions(request, new ApiPwaOfflineSyncOptions(body));
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts')>({ ApiPwaOfflineSyncOptions, isGenerateOfflineDataOptions });
})();
